body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #e8eceeab !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.center-component {
  display        : flex;
  justify-content: center;
  align-items    : center;
  vertical-align : middle;
}

.linear-component {
  display        : flex;
  flex-direction : row;
  justify-content: center;
  align-items    : center;
  vertical-align : middle;
}

.img-leng {
  margin: 10px;
  cursor: pointer;
}

.content-top {
  position: fixed;
  top     : 5px;
  right   : 5px;
}

.img-leng-selected {
  padding      : 2px;
  border       : 1px solid #df7414;
  border-radius: 5px;
  box-shadow   : 0px 0px 10px #df7414;
}




.fieldSearch {
  background   : #E5E7E9 !important;
  padding      : 10px;
  border-radius: 5px;
}

.fieldSearch input {
  border          : 0px solid transparent !important;
  background-color: transparent !important;
  border-color    : transparent !important;
  box-shadow      : 0 0 0 0 transparent;

}

.fieldSearch input:hover {
  border          : 0px solid transparent !important;
  background-color: transparent !important;
  border-color    : transparent !important;
  box-shadow      : 0 0 0 0 transparent;
}

.fieldSearch input:active {
  border          : 0px solid transparent !important;
  background-color: transparent !important;
  border-color    : transparent !important;
  box-shadow      : 0 0 0 0 transparent;
}


.fieldSearch input:focus {
  border          : 0px solid transparent !important;
  background-color: transparent !important;
  border-color    : transparent !important;
  box-shadow      : 0 0 0 0 transparent;

}

.text-left {
  text-align: left;
}

.hr-personlization {

  width        : 100%;
  background   : #b3b3b3;
  height       : 1px;
  margin-bottom: 5px;

}

.form-control-text {
  height                    : 67px !important;
  margin-top                : 0px !important;
  margin-bottom             : 0px !important;
  border-top-right-radius   : 0px !important;
  border-bottom-right-radius: 0px !important;
}

.form-control-border-group {
  border-top-left-radius   : 0px !important;
  border-bottom-left-radius: 0px !important;
  cursor                   : pointer;
}

.text-danger-message {
  color    : #c9414b;
  font-size: 20px;
}

.filter-new-filter {
  height         : 50px;
  display        : flex;
  justify-content: center;
  align-items    : center;
}

.card-success {
  border    : none !important;
  box-shadow: 0px 0px 5px 0px #1bc5bd !important;
  min-height: 106px;

}

.card-success .description {
  font-weight: 300;

}

.card-success i {
  color: #1bc5bd !important;
}

.text-card-success {
  color: #1bc5bd !important;
}

.card-info {
  border    : none !important;
  box-shadow: 0px 0px 5px 0px #3498DB !important;
  min-height: 106px;

}

.card-info .description {
  font-weight: 300;

}

.card-info i {
  color: #3498DB !important;
}

.text-card-info {
  color: #3498DB !important;
}




.card-danger {
  border    : none !important;
  box-shadow: 0px 0px 5px 0px #CB4335 !important;
  min-height: 106px;

}

.card-danger .description {
  font-weight: 300;

}

.card-danger i {
  color: #CB4335 !important;
}

.text-card-danger {
  color: #CB4335 !important;
}

.card-assistant {
  margin       : 10px !important;
  padding: 30px 15px !important;
  border       : none !important;
  border-radius: 5px !important;
  box-shadow   : 0px 0px 10px 0px #fff !important;
}

.card-assistant .fieldsAssistant {
  display       : flex !important;
  flex-direction: row !important;
  font-weight   : 400;
}

.card-assistant .fieldsAssistant b:first-child {
  -webkit-flex: auto;
  flex        : auto;
}

.card-assistant .fieldsAssistant span:last-child {
  -webkit-flex: auto;
  flex        : auto;
  text-align  : right;
}

.title-name {
  font-size  : 18px;
  font-weight: 600;
}

.border-left-asssitant-info {
  border-left: 5px solid #3598da !important;
}


.border-left-asssitant-success {
  border-left: 5px solid #1bc5bd !important;
}

.border-left-asssitant-danger {
  border-left: 5px solid #CB4335 !important;
}

.border-left-asssitant-warning {
  border-left: 5px solid #F1C40F !important;
}

.btn-outline-info {
  border: 1px solid #3598da !important;
  color : #3598da !important;
}

.btn-outline-info:hover {
  background-color: #3598da !important;
  color           : white !important;
}

.btn-outline-info:active {
  background-color: #3598da !important;
  color           : white !important;
  box-shadow      : 0px 0px 10px 0px #3598da !important;
}

.btn-outline-info:focus {
  box-shadow      : 0px 0px 10px 0px #3598da !important;
}

.danger-text-status{
  background: #C0392B;
    padding: 10px;
    margin: 2px;
    border-radius: 5px;
    color: white;
}

.font-size-14{
  font-size: 14px;
}

.control-form{
  width: 100% !important;
}



@media (max-width: 768px) {
  .center-component-mobil {
    text-align: center;
    margin-bottom: 2px;
  }
}

.buttinlink {
  display: flex;
    justify-content: center;
    align-items: center;
}

.buttinlink button {
  width: 40px;
  height: 100% !important ;
}


