.fondo-login {
    background       : #f6f5f7;
    position         : fixed;
    top              : 0;
    left             : 0;
    right            : 0;
    bottom           : 0;
    z-index          : -1;
    background-image : url("https://aplicacionesincontacto.com/inexmoda/assets/images/background/banner_new_inexmoda-1.jpg");
    background-repeat: no-repeat;
    background-size  : cover;
}


.fondo-login-2 {
    position         : fixed;
    top              : 0;
    left             : 0;
    right            : 0;
    bottom           : 0;
    z-index          : -1;
    background-image : url("https://aplicacionesincontacto.com/inexmoda/assets/images/dotted-overlay.png");
}

.content-icon {
    position       : absolute;
    left           : 0;
    right          : 0;
    display        : flex;
    flex-direction : column;
    text-align     : center;
    justify-content: center;
    align-items    : center;
    top            : -50px;
    width          : 100%;

}

.icon-position {
    height            : 100px;
    width             : 100px;
    /* border-radius     : 50%; */
    padding           : 10px;
    display           : flex;
    flex-direction    : column;
    text-align        : center;
    justify-content   : center;
    align-items       : center;
    border            : 1px solid #f6f5f7;
    background-color  : #fff;
    -webkit-box-shadow: -1px 0px 7px 0px rgba(246, 245, 247, 1);
    -moz-box-shadow   : -1px 0px 7px 0px rgba(246, 245, 247, 1);
    box-shadow        : -1px 0px 7px 0px rgba(246, 245, 247, 1);

}

.icon-position img {
    width: 80%;
}

.content-option {
    padding-top: 40px;
}

.hr-personalization {
    width     : 50%;
    border-top: 2px solid rgb(33 38 41);
}

.content-login {
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
    text-align     : center;
    width          : 100%;
    height         : 100vh;
}

.card-personalizatioin-login {
    border            : 1px solid #F2F3F4;
    -webkit-box-shadow: -1px 0px 7px 0px rgba(246, 245, 247, 1);
    -moz-box-shadow   : -1px 0px 7px 0px rgba(246, 245, 247, 1);
    box-shadow        : -1px 0px 7px 0px rgba(246, 245, 247, 1);
}

.conten-botton-left {
    position        : fixed;
    left            : -100px;
    bottom          : -100px;
    background-color: #0091ce;
    width           : 300px;
    height          : 300px;
    border-radius   : 50%;
    z-index         : -1;
}

.conten-botton-rigth {
    position        : fixed;
    right           : -100px;
    bottom          : -100px;
    background-color: #d4e8f7;
    width           : 200px;
    height          : 200px;
    border-radius   : 50%;
    z-index         : -1;
}


.conten-top-left {
    position        : fixed;
    left            : -100px;
    top             : -100px;
    background-color: #d4e8f7;
    width           : 300px;
    height          : 300px;
    border-radius   : 50%;
    z-index         : -1;
}

.conten-top-rigth {
    position: fixed;
    right   : -100px;
    top     : -100px;

    background-color: #0091ce;
    width           : 200px;
    height          : 200px;
    border-radius   : 50%;
    z-index         : -1;
}


form {
    background-color: #FFFFFF;
    display         : flex;
    align-items     : center;
    justify-content : center;
    flex-direction  : column;
    padding         : 0;
    height          : 100%;
    text-align      : center;
}

input {
    background-color: #f0f0f1;
    border          : none;
    padding         : 12px 15px;
    margin          : 8px 0;
    width           : 100%;
    border-radius   : 5px;
    margin-bottom   : 7px !important;
    margin-top      : 7px !important;
}

input:focus {
    border: 1px solid #0091ce !important;
}

input:focus-visible {
    border: 1px solid #0091ce !important;
}

a {
    color          : #333;
    font-size      : 14px;
    text-decoration: none;
    margin         : 15px 0;
}

button {
    border-radius   : 10px;
    background: #ef5350;
    border: 1px solid #ef5350;
    color           : #FFFFFF;
    font-size       : 12px;
    font-weight     : bold;
    padding         : 12px 45px;
    letter-spacing  : 1px;
    text-transform  : uppercase;
    transition      : transform 80ms ease-in;
    width           : 100%;
}

button:active {
    transform: scale(0.95);
}

button:focus {
    outline: none;
}

button.ghost {
    background-color: transparent;
    border-color    : #FFFFFF;
}

.error-field {
    text-align : left !important;
    color      : #C0392B !important;
    font-size  : 15px !important;
    width      : 100%;
    margin-left: 10px;
    margin-top : -8px;
}