footer {
    width             : 100%;
    height            : 70px;
    /* background        : hsl(47deg 100% 49%); */
    background: hsl(60deg 2% 11%);
    -webkit-box-shadow: -1px 0px 7px 0px rgba(246, 245, 247, 1);
    -moz-box-shadow   : -1px 0px 7px 0px rgba(246, 245, 247, 1);
    box-shadow        : -1px 0px 7px 0px rgba(246, 245, 247, 1);
    display           : flex;
    flex-direction    : row;
    position          : fixed;
    left              : 0;
    right             : 0;
    bottom            : 0;
    z-index           : 3;
}


header {
    width             : 100%;
    height            : 70px;
    /* background        : hsl(47deg 100% 49%); */
    background: hsl(60deg 2% 11%);
    -webkit-box-shadow: -1px 0px 7px 0px rgba(246, 245, 247, 1);
    -moz-box-shadow   : -1px 0px 7px 0px rgba(246, 245, 247, 1);
    box-shadow        : -1px 0px 7px 0px rgba(246, 245, 247, 1);
    display           : flex;
    flex-direction    : row;
    position          : fixed;
    left              : 0;
    right             : 0;
    top               : 0;
    align-items       : center;
    vertical-align    : middle;
    z-index           : 3;
}

header div:first-child img {
    width : 200px !important;
    /* height: 50px !important; */
}

header div {
    width: 100%;
}


.contentview {
    margin-top   : 70px !important;
    margin-bottom: 70px !important;
}

.contentviewOnlyTop {
    margin-top: 70px !important;
}

.btn-personalization-transparente {
    background: transparent;
    border    : none;
}